import {localize} from "../../localize/localize";
import Config from '../../config/index'

// list language
const arrLanguage = ['vi', 'en', 'ja', 'zh'];

// map key between CORE and UI
const arrKey = {
    "dropFileHere": "Keo_va_tha_tap_tin_dinh_kem_vao_day"
};

export let localizeCoreUI = {};

arrLanguage.forEach(i=>{
    localizeCoreUI[i] = {};
    Object.keys(arrKey).forEach(key=>{
        localizeCoreUI[i][key] = localize[i][`${Config?.env?.prefixLang}${arrKey[key]}`];
    })
});


